import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'tree-component',
    templateUrl: './tree.component.html',
    styleUrl: './tree.component.scss'
})
export class TreeComponent {
    @Input() allDeviceInfoNodes: TreeNode<any>[];
    @Input() selectionMode: string = 'single';
    private _selectedNode: any;
    get selectedNode(): any {
        return this._selectedNode;
    }
    @Input() set selectedNode(value: any) {
        this.selectedNodeChange.emit(value)
        this._selectedNode = value;
    }
    @Output() selectedNodeChange = new EventEmitter<any>();
    @Output() emittedNodeSelected: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();
    @Output() emittedNodeDeSelected: EventEmitter<TreeNode> = new EventEmitter<TreeNode>();


    onNodeSelected(node: TreeNode<any>) {
        this.emittedNodeSelected.emit(node);
    }

    onNodeSelectedOrDeselected(node: TreeNode<any>) {
        this.emittedNodeSelected.emit(node);
    }
}
