@if(showTableHeaderName) {
<div class="p-mb-2 p-text-left headertext">
    <i [class]="icon"></i> {{ tableHeaderName | translate}}
</div>
}

<p-table #dt
         [columns]="permittedColumns"
         [scrollable]="true"
         scrollHeight="200px"
         [value]="tableData"
         styleClass="p-datatable-sm p-datatable-responsive-demo"
         [selection]="selectedData"
         (selectionChange)="onSelectionChange($event)"
         [selectAll]="selectAll"
         [resizableColumns]="true"
         (selectAllChange)="onSelectAllChange($event)"
         (sortFunction)="customSort($event)"
         [customSort]="true">
    <ng-template #defaultToolbarRight>
        <div></div>
    </ng-template>
    <ng-template pTemplate="caption">
        <div class="row">
            <div class="col-md"
                 *ngIf="showSearchBar || downloadData">
                <div class="">
                    <span class="p-input-icon-right"
                          *ngIf="showSearchBar">
                        <input pInputText
                               type="text"
                               [(ngModel)]="searchCharacter"
                               (input)="applyFilterGlobal($event, 'contains')"
                               [placeholder]="searchPlaceholder" />
                    </span>
                    <span *ngIf="downloadData"
                          class="download-buttons">
                        <button type="button"
                                pButton
                                pRipple
                                icon="pi pi-file"
                                (click)="dt.exportCSV()"
                                class="mr-2 csv-btn"
                                pTooltip="CSV"
                                tooltipPosition="bottom"></button>
                        <button type="button"
                                pButton
                                pRipple
                                icon="pi pi-file-excel"
                                (click)="exportExcel()"
                                style="height: 34px;"
                                class="p-button-success mr-2"
                                pTooltip="XLS"
                                tooltipPosition="bottom"></button>
                    </span>
                </div>
            </div>
            <div class="col-md-6 text-right"
                 *ngIf="showTelemetryRouteButton">
                <ng-container *ngTemplateOutlet="toolbarRight ? toolbarRight : defaultToolbarRight"></ng-container>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header"
                 let-columns>
        <tr>
            <th style="width: 4rem"
                *ngIf="showCheckbox">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            @for (col of columns; let idx = $index; track idx) {
            <th [style.width]="col.header ? '' : '15px'"
                pResizableColumn
                [pSortableColumn]="col.sort && col.field">
                {{ col.header | translate }} <p-sortIcon *ngIf="col.header"
                            [field]="col.field"></p-sortIcon>
            </th>
            }

        </tr>
    </ng-template>
    <ng-template pTemplate="body"
                 let-rowData
                 let-columns="columns"
                 let-i="rowIndex">
        <a [routerLink]="enableClickRowToRoute ? [routeParam.route] : null"
           [queryParams]="enableClickRowToRoute ? createQueryParams(rowData, routeParam.keys, routeParam.props) : {}">
            <tr>
                @if(showCheckbox) {
                <td>
                    <p-tableCheckbox [value]="rowData"
                                     (click)="onClickCheckbox($event)"></p-tableCheckbox>
                </td>
                }
                @for (col of columns; let idx = $index; track idx) {
                <td>
                    @switch (col.type) {

                    @case ('bool') {
                    <span>
                        <input type="checkbox"
                               [checked]="isChecked(rowData[col.field])"
                               disabled />
                    </span>
                    }
                    @case ('circleBeforeText') {
                    <span class="circle"
                          [style.backgroundColor]="getCircleColor(rowData)"></span>
                    }
                    @case ('percent') {
                    <span pTooltip="{{getTooltip(rowData, col)}}">
                        {{ rowData[col.field] ? (rowData[col.field] / 100 | percent : '1.1-1') : null }}
                    </span>
                    }
                    @case ('percentage') {
                    <span pTooltip="{{getTooltip(rowData, col)}}">
                        {{ rowData[col.field] ? (rowData[col.field] | percent : '1.1-1') : null }}
                    </span>
                    }
                    @case ('date') {
                    <span pTooltip="{{getTooltip(rowData, col)}}">
                        {{ rowData[col.field] | date}}
                    </span>
                    }
                    @case ('datetime') {
                    <span pTooltip="{{getTooltip(rowData, col)}}">
                        {{ rowData[col.field] | date: 'medium'}}
                    </span>
                    }
                    @case ('ago') {
                    <span pTooltip="{{rowData[col.field] | date: 'medium'}}"
                          tooltipPosition="bottom">
                        {{ rowData[col.field] | dateAgo}}
                    </span>
                    }
                    @case ('wattHours') {
                    <span pTooltip="{{getTooltip(rowData, col)}}">
                        {{ rowData[col.field] | kilomega : '1.1-1' }}Wh
                    </span>
                    }
                    @case ('watts') {
                    <span pTooltip="{{getTooltip(rowData, col)}}">
                        {{ rowData[col.field] | number : '1.0-0' }}W
                    </span>
                    }
                    @case ('ampHours') {
                    <span pTooltip="{{getTooltip(rowData, col)}}">
                        {{ rowData[col.field] | number : '1.0-0' }}Ah
                    </span>
                    }
                    @case ('temperature') {
                    <span pTooltip="{{getTooltip(rowData, col)}}">
                        {{ rowData[col.field] | number : '1.1-1' }}°C
                    </span>
                    }
                    @case ('voltage') {
                    <span pTooltip="{{getTooltip(rowData, col)}}">
                        {{ rowData[col.field] | number : '1.3-3' }}V
                    </span>
                    }
                    @case ('current') {
                    <span pTooltip="{{getTooltip(rowData, col)}}">
                        {{ rowData[col.field] | number : '1.2-2' }}A
                    </span>
                    }
                    @case ('state') {
                    <span pTooltip="{{getTooltip(rowData, col)}}">
                        {{ rowData[col.field] }}
                    </span>
                    }
                    @case ('status') {
                    <span pTooltip="{{getTooltip(rowData, col)}}">
                        <span class="circle"
                              [style.backgroundColor]="getCellColour(rowData, col)"></span>
                        {{ rowData[col.field] }}
                    </span>
                    }
                    @case ('error') {
                    <span pTooltip="{{getTooltip(rowData, col)}}">
                        {{ rowData[col.field] | decamel }}
                    </span>
                    }

                    @default {
                    <span>
                        <span pTooltip="{{rowData[col.field]}}"
                              tooltipPosition="bottom"> {{ rowData[col.field] }} </span>
                    </span>
                    }
                    }
                </td>
                }
            </tr>
        </a>
    </ng-template>
</p-table>

@if(tableData?.length == 0) {
<p class="placeholder">{{tableLoadingState}}</p>
}